import Cancel from "./routes/Cancel.svelte"
import ErrorPage from "./routes/Error.svelte"
import Failure from "./routes/Failure.svelte"
import Home from "./routes/Home.svelte"
import NewSubscription from "./routes/NewSubscription.svelte"
import Success from "./routes/Success.svelte"

const routes = {
  "/cancel": Cancel,
  "/": Home,
  "/new-subscription*": NewSubscription,
  "/new-subscription/*": NewSubscription,
  "/success*": Success,
  "/failure*": Failure,

  // Catch-all
  "*": ErrorPage,
}

export default routes
